import React from 'react'

// import { Helmet } from 'react-helmet'
// import styled from 'styled-components'
// import {
//   Flex,
//   Box,
//   Title3,
//   Large,
//   colors,
// } from 'js-components'
// import { Section, WrapperBackground } from '../../components/Wrapper'
// import { mobileBreakpoint } from '../../constants'
// import { SignUpFormCard } from '../../components/SignUp'
// import { VideoFrame } from '../../components/VideoFrame'
// import Default from '../../components/Layouts/Default';

// const AboutPage = () => (
//       <Default>
//       <Helmet>
//         <meta charset="UTF-8" />
//         <title>Request a Demo</title>
//         <meta name="description" content="Get your team set up with Truly today." />
//       </Helmet>
//       <WrapperBackground
//       color={colors.trulyDark}
//       background="sf"
//       >
//         <Section pt='84px'>
//           <Flex flexDirection={['column', 'row', 'row']}>
//             <Box center width={[1, 1/2, 1/2]}>
//               <SignUpFormCard />
//             </Box>
//             <Box width={[1, 1/2, 2/3]} m={[0, "0rem 0rem 0rem 2rem"]} p={["2rem", "0rem 2rem"]}>
//               <VideoFrame background="explainer" caption="What is Truly?" />
//               <Title3 center as="p" color={colors.accentFog} my="2rem">
//                 CONNECT EVERY CONVERSATION TO REVENUE
//               </Title3>
//               <Large as="h1" color={colors.white} mb="1.5rem">
//                 <b>Works Everywhere.</b> With exclusive Tier 1 providers partnerships and apps for every device, Truly makes crystal-clear calls from anywhere.
//               </Large>
//               <Large as="h1" color={colors.white} mb="1.5rem">
//                 <b>Focus on Call Performance.</b> We built a different type of enterprise voice system from the ground up. Using proprietary technology, Truly maximizes call reliability and ensures IT is always in the driver's seat.
//               </Large>
//               <Large as="h1" color={colors.white} mb="1.5rem">
//                 <b>Capture Data in the Right Place.</b> Your CRM is the heart of your organization. Truly automatically captures, logs and analyzes every call your team makes, inside of your CRM.
//               </Large>
//             </Box>
//           </Flex>
//         </Section>
//     </WrapperBackground>
//     </Default>
// )

// const WidthImg = styled.img`
//   width: 100%;
// `

// const LandingWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100vw;
//   min-height: 100vh;
//   background-color: black;
// `

// const LogoWrapper = styled.a`
//   display: flex;
//   width: 100vw;
//   padding-left : 64px;
//   margin-top: 28px;

//   @media (max-width:${mobileBreakpoint}) {
//     padding-left: 0px;
//     justify-content: center;
//   }
// `

//temporary redirect until forms are fixed
export default () => {
  if (typeof window !== "undefined") {
  window.location.href = "/";
  }
  return <div/>;
}
